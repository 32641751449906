import * as React from "react"
import Contacto from "../components/contacto"
import Layout from "../components/layout/es"



// markup
const ContactoPage = (props) => {

  return (
    <Layout location={props.location} pageId="contact" metaTitle="Contacto | Crequs">
      <Contacto privacy_link="/politica-de-privacidad" />
    </Layout>
  )
}

export default ContactoPage


